<template>
  <!-- 拒单原因 -->
  <el-dialog
    :title="$t('c2324')"
    width="600px"
    top="5%"
    style="padding: 20px 30px"
    :visible.sync="orderRejectFlagProp"
    append-to-body
    :close-on-click-modal="false"
    @close="cancelRejectFlag"
  >
    <div class="rejection-box">
      <!-- 拒单原因 -->
      <el-select
        popper-class="reason-rejection"
        v-model="reasonRejectionchoose"
        placeholder="请选择"
      >
        <el-option
          v-for="item in reasonRejectionData"
          :key="item.codevalue"
          :label="item.codevaluelang"
          :value="item.codevalue"
        >
        </el-option>
      </el-select>
      <el-input
        popper-class="reason-rejectioninput"
        type="textarea"
        :placeholder="$t('c2325')"
        v-model="reasonRejectionValue"
        maxlength="500"
        show-word-limit
      >
      </el-input>
    </div>
    <span slot="footer">
      <el-button @click="cancelRejectFlag" size="mini">
        {{ $t("label.cancel") }}
      </el-button>
      <el-button type="primary" @click="utOrderReject"  :loading="utOrderRejectLoading" size="mini">
        {{ $t("label.confirm") }}
      </el-button>
    </span>
  </el-dialog>
</template>
  
<script>
import * as CommonObjApi from "./api.js";
export default {
  props: {
    // 是否显示拒单原因弹框
    utOrderRejectFlag: {
      type: Boolean,
      default: false,
    },
    // 服务预约id
    serviceappointmentId: {
      type: String,
      default: "",
    },
    // 服务区域id
    serviceterritoryId:{
      type: String,
      default: "",
    }
  },
  data() {
    return {
      // 拒单原因数据
      reasonRejectionData: [],
      //拒单原因选项
      reasonRejectionchoose: "",
      //拒单原因500字
      reasonRejectionValue: "",
      // 点击拒单确定按钮loading
      utOrderRejectLoading:false,
    };
  },
  computed: {
    orderRejectFlagProp: {
      get() {
        return this.utOrderRejectFlag;
      },
      set(val) {
        this.$emit("update:utOrderRejectFlag", val);
      },
    },
  },
  mounted(){
    this.getRejectReason();
    this.reasonRejectionValue = "";
  },  
  methods: {
    // 关闭拒单弹框
    cancelRejectFlag() {
      this.$emit("cancelOrderRejectFlag");
    },
    // 拒单接口
    async utOrderReject() {
      this.utOrderRejectLoading=true;
      let params = {
        serviceappointmentId: this.serviceappointmentId, //服务预约id
        rejectReason: this.reasonRejectionchoose, //拒单原因,
        rejectDetail: this.reasonRejectionValue, //详细原因
        serviceterritoryId:this.serviceterritoryId,//服务区域id
      };
      let res = await CommonObjApi.utOrderReject(params);
      if (res.result&&res.returnCode!='2') {
        this.$message.success(this.$i18n.t("c2178"));
        this.reasonRejectionchoose = "";
        this.$emit("cancelOrderRejectFlag","success");
      } else {
        this.$message.error(this.$i18n.t("c2177"));
        this.$emit("cancelOrderRejectFlag");
      }
      this.utOrderRejectLoading=false;
    },
    // 拒单原因接口
    async getRejectReason() {
      this.reasonRejectionchoose = this.$i18n.t("c2323");
      let res = await CommonObjApi.getRejectReason();
      if (res.result && res.data) {
        this.reasonRejectionData = res.data;
      }
    },
  },
};
</script>
  
<style lang="scss" scoped>
.rejection-box {
  ::v-deep .el-select {
    width: 360px;
    height: 32px;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    margin-bottom: 16px;
    .el-input__inner {
      height: 32px;
      line-height: 32px;
    }
  }
  ::v-deep .el-textarea {
    width: 540px;
    height: 222px;
    background: #fafafa;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    .el-textarea__inner {
      height: 222px;
    }
  }
}
</style>